.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
}
.childOne{
  width: 100%;
}
.childTwo{
  width: 100%;
}
