@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-Regular.eot');
  src: local('TT Commons Regular'), local('TTCommons-Regular'),
    url('../../fonts/TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-Regular.woff') format('woff'),
    url('../../fonts/TTCommons-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-Medium.eot');
  src: local('TT Commons Medium'), local('TTCommons-Medium'),
    url('../../fonts/TTCommons-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-Medium.woff') format('woff'),
    url('../../fonts/TTCommons-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-DemiBold.eot');
  src: local('TT Commons DemiBold'), local('TTCommons-DemiBold'),
    url('../../fonts/TTCommons-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-DemiBold.woff') format('woff'),
    url('../../fonts/TTCommons-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.newBlockVerify {
  display: flex;
  flex-direction: column;
  margin-bottom: -84px;
  align-items: center;
}

.card1 {
  display: flex;
  padding: 6px 8px 12px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Gradient-stroke, #dcebf3);
  background: var(
    --Gradient-card,
    linear-gradient(100deg, #e3f4fb 9.2%, #f4e4f7 81.9%, #d3ccff 119.85%)
  );
  box-shadow: 2px 10px 40px 0px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  max-width: 280px;
  position: relative;
  right: 5px;
}
.card2 {
  width: 100%;
  border-radius: 8px;
  background: #edeef7;
  padding-top: 15px;
  position: relative;
  max-width: 280px;
  top: 0;
  left: 5px;
  top: -96px;
  min-height: 160px;
}
.verify_form_input {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cvv_bgc {
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ),
    #eff0fa;
  height: 24px;
  flex-shrink: 0;
}
.cvv_input {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 5px;
}
.titleVerifyNew {
  color: #312360;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.holder_input {
  width: 100%;
}

.container_new {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  width: 90%;
  min-height: 250px;
}
.container_new {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(103, 87, 156, 0.25);
  border-radius: 10px;
  padding: 50px 200px;
}

.expiry_input {
  width: 80px;
}
.btn__new_verify {
  width: 280px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 60px;
  background: var(--Button_text, #67579c);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.button_spin {
  display: flex;
  justify-content: center;
}

.icon_block {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cvv_helper_text {
  color: #515151;
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100px;
}
.icon_merchant {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_block {
  position: relative;
  display: flex;
  flex-direction: column;
}
.verify_form_input {
  gap: 7px;
}
.small_input_new {
  display: flex;
  gap: 10px;
  width: 150px;
  align-items: center;
}
.cvv_input {
  margin-top: 60px;
}
.btn__new_verify {
  width: 280px;
}
.cvv_helper_text {
  width: 200px;
  margin-top: 23px;
}

.oval {
  position: absolute;
  right: 30px;
  top: -23px;
  width: 35px;
}

.cvv_helper_text_error{
  margin-top: -9px;
}

@media screen and (max-width: 768px) {
  .container_new {
    padding: 15px calc((90vw - 315px) / 2);
  }
  .icon_merchant {
    margin-bottom: 10px;
  }
  .newBlockVerify {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: -100px;
  }
  .card1 {
    position: relative;
    left: -5px;
  }
  .card2 {
    position: relative;
    max-width: 280px;
    top: 0;
    left: 5px;
    top: -116px;
    min-height: 160px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info_block {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .verify_form_input {
    gap: 7px;
  }
  .small_input_new {
    display: flex;
    gap: 10px;
    width: 150px;
    align-items: center;
  }
  .cvv_input {
    margin-top: 60px;
  }
  .btn__new_verify {
    width: 280px;
  }
  .cvv_helper_text {
    width: 200px;
    margin-top: 24px;
  }
  .cvv_helper_text_error{
    margin-top: -9px;
  }
}
