.user-agreement {
  color: #a7adb5;
  font-weight: 300;
  font-size: 12px;
  padding: 12px 0 10px 0;
  &__text {
    color: #959BA4;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  &__link {
    color: #3ab2e1;
    text-decoration: underline;
  }
}
