@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-Regular.eot');
  src: local('TT Commons Regular'), local('TTCommons-Regular'),
    url('../../fonts/TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-Regular.woff') format('woff'),
    url('../../fonts/TTCommons-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-Medium.eot');
  src: local('TT Commons Medium'), local('TTCommons-Medium'),
    url('../../fonts/TTCommons-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-Medium.woff') format('woff'),
    url('../../fonts/TTCommons-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../../fonts/TTCommons-DemiBold.eot');
  src: local('TT Commons DemiBold'), local('TTCommons-DemiBold'),
    url('../../fonts/TTCommons-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/TTCommons-DemiBold.woff') format('woff'),
    url('../../fonts/TTCommons-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  box-sizing: border-box;
  list-style: none;
  border-radius: 0;
  color: #130e21;
  font-family: 'TT Commons', sans-serif;
}

b,
strong {
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  letter-spacing: inherit;
}

select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

strong,
b {
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

strong *,
b * {
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

em,
i {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

em *,
i * {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

*:focus {
  outline: transparent;
}

.container_540 {
  position: relative;
  width: 90%;
  max-width: 540px;
  margin: 0 auto;
  min-height: 250px;
}

.container_920 {
  width: 90%;
  max-width: 920px;
  margin: 0 auto;
}

body {
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body main {
  overflow: hidden;
}

.main * {
  font-family: 'TT Commons', sans-serif;
}

.main {
  background-color: #ffffff;
}

body footer {
  margin-top: auto;
  margin-bottom: 0;
}

input[type='submit'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.btn__purple:disabled,
.btn__purple[disabled] {
  background: #ebebee;
}

.btn img {
  height: 17px;
  width: auto;
  display: block;
}

.btn__purple {
  background: #67579c;
  transition: 0.4s;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0%;
}

.btn__purple:hover:not([disabled]) {
  background: #7664b3;
}

.btn__orange {
  background: #ffa500;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  transition: 0.4s;
}

.btn__orange:hover {
  background: #e7990dff;
}

.btn__grey {
  background: #f7f7fa;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  transition: 0.4s;
}

.btn__grey:hover {
  background: #ebebee;
}

.header * {
  font-family: 'TT Commons', sans-serif;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #f7f7fa;
  padding: 15px 0;
  position: relative;
  z-index: 1000;
  font-family: 'TT Commons', sans-serif;
}

.header .container_920 {
  display: flex;
  align-items: center;
  font-family: 'TT Commons', sans-serif;
}

.header__logo {
  height: 22px;
  display: block;
  margin-right: auto;
}

.header__logo img {
  display: block;
  width: auto;
  height: 100%;
}

.header__phone {
  height: 30px;
  font-family: inherit;
}

.header__phoneWindow {
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 5px;
  height: 27px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: 0.4s;
}

.header__phoneWindow.phones_num_1 ul {
  padding-right: 0;
}

.header__phoneWindow.phones_num_1 ul:before {
  display: none;
}

.header__phoneWindow.phones_num_2:hover {
  height: 52px;
  border: 1px solid #f7f7fa;
}

.header__phoneWindow.phones_num_3:hover {
  height: 72px;
  border: 1px solid #f7f7fa;
}

.header__phoneWindow.phones_num_4:hover {
  height: 92px;
  border: 1px solid #f7f7fa;
}

.header__phoneWindow.phones_num_5:hover {
  height: 112px;
  border: 1px solid #f7f7fa;
}

.header__phone svg {
  display: block;
  width: 14px;
  height: 16px;
  margin-right: 5px;
}

.header__phone ul,
.header__phone .header__list {
  padding-right: 17px;
  position: relative;
}

.header__phone ul:before,
.header__phone .header__list:before {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border: 1px solid #130e21;
  -webkit-transform: translate(-2px, -75%) rotate(45deg);
  transform: translate(-2px, -75%) rotate(45deg);
  border-left: none;
  border-top: none;
}

.header__phone a,
.header__phone .header__link {
  display: block;
  padding: 2px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  transition: 0.4s;
  font-family: inherit;
}

.header__phone a:hover,
.header__phone .header__link:hover {
  color: #67579c;
}

.footer {
  background: #67579c;
  padding: 40px 0 25px;
  position: relative;
  font-family: 'TT Commons', sans-serif;
}

.footer * {
  font-family: 'TT Commons', sans-serif;
}

.footer__top {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.footer p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #e9e9e9;
}

.footer p span {
  color: white;
}

.footer__col {
  width: 100%;
  position: relative;
}

.footer__col:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 1px;
  height: 75px;
  background: #5b4a92;
}

.footer__col:last-child:before {
  display: none;
}

.footer__contacts .label {
  margin-bottom: 20px;
}

.footer__contacts a {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: white;
  transition: 0.4s;
  border-bottom: 1px solid white;
  margin-bottom: 15px;
}

.footer__contacts a:last-child {
  margin-bottom: 0;
}

.footer__contacts a:hover {
  color: #e9e9e9;
  border-bottom-color: transparent;
}

.footer__worktime {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__worktime p {
  margin-bottom: 10px;
}

.footer__worktime p:last-child {
  margin-bottom: 0;
}

.footer__worktime p span {
  padding-left: 15px;
}

.footer__socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer__socials a {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 50px;
}

.footer__socials a:last-child {
  margin-right: 0;
}

.footer__socials a svg {
  display: block;
  width: 100%;
  height: 100%;
}

.footer__socials a svg * {
  fill: white;
  transition: 0.4s;
}

.footer__socials a:hover svg * {
  fill: #e9e9e9;
}

.blockForm {
  padding: 100px 0;
}

.blockForm h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 15px;
}

.blockForm__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  &.blockForm__top--no-mb {
    margin-bottom: 0;
  }
  &.blockForm__top--xs-mb {
    margin-bottom: 10px;
  }
}

.info-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.alert_limits {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: red;
}

.blockForm__top p {
  color: var(--Small-legal-text, #747474);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blockForm__top .price {
  color: var(--description-text-landing, #515151);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blockForm__form > * {
  margin-bottom: 30px;
}

.blockForm__logotypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.blockForm__logotypes img {
  display: block;
  width: auto;
  height: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}

.blockForm .inout_row {
  display: flex;
}

.blockForm .inout_row > * {
  margin-right: 30px;
}

.blockForm .inout_row > *:last-child {
  margin-right: 0;
}

.blockForm .input_box {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockForm .input_box input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #bac3d5;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.blockForm .input_box label {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #697395;
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 23px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 2px 5px;
  transition: 0.4s;
  border-radius: 3px;
}

.blockForm .input_box input:focus + label,
.blockForm .input_box.not_empty label {
  top: 1px;
  left: 10px !important;
  font-size: 13px;
  background: white;
}

.blockForm .input_box.input_box_icon .icon {
  position: absolute;
  left: 9px;
  top: 22px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: #f7f7fa;
  border-radius: 3px;
  padding: 6px;
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockForm .input_box.input_box_icon .icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.blockForm .input_box.input_box_icon label {
  left: 50px;
}

.blockForm .input_box.input_box_icon input {
  padding-left: 50px;
}

.input_box .error_text {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #ea1717;
  padding-top: 5px;
  display: none;
}
.price_fee {
  color: var(--description-text-landing, #515151);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input_box {
  &.invalid input {
    border-color: #ea1717;
  }
  &.valid input {
    border-color: rgb(0, 255, 0);
  }
}

.input_box.invalid .error_text {
  display: block;
}

.price_fee {
  color: var(--description-text-landing, #515151) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.new_btn_phone_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #000;
    background: #fff;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.pay_choice {
  color: #515151;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .header {
    padding: 12px 0;
  }

  .footer {
    padding: 30px 0 40px;
  }

  .footer__top {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .footer__col:before {
    display: none;
  }

  .footer__contacts {
    margin-bottom: 30px;
  }

  .footer__worktime {
    align-items: flex-start;
  }

  .footer__socials {
    position: absolute;
    right: 5vw;
    top: 102px;
  }

  .footer__socials a {
    margin-right: 30px;
  }

  .footer__bot p {
    font-size: 10px;
    line-height: 2;
  }

  .blockForm {
    padding: 25px 0;
  }

  .blockForm h1 {
    font-size: 20px;
    line-height: 25px;
  }
}

.centred-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
