.security-block {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  padding: 10px;
  flex-grow: 0;
  margin: 10px 0px;
  border-radius: 4px;

  background: rgb(249, 249, 249);
}

.security-text {
  color: rgb(149, 155, 164);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: left;
}
