.google-pay-btn {
  flex-grow: 1;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100%;
}
.google-pay-btn.google-pay-button-container {
  display: block;
}
.google-pay-button-container {
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
}
.gpay-card-info-container.black {
  outline: none !important;
}