.apple-pay-button {
  display: none;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    flex-grow: 1;
    display: block;
    padding: 20px;
    // max-width: 280px;
    margin: 0 auto;
    border-radius: 5px;
    -webkit-appearance: -apple-pay-button;
    text-indent: -9999px;
    width: 100%;
    height: 40px;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
